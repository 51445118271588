body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}

.routes-container {
  margin-top: 20px;
}

.caro-buttons {
  margin-top: 20px;
  flex-direction: column;
  display: flex;
}

.number-carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  /* background-color: #f4f4f4; */
  /* color: #333; */
}

.img-classification {
  padding: 15px;
}


code {
  font-family: 'Courier New', Courier, monospace;
}

/* Navigation Styles */
nav {
  background: #333;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  z-index: 12;
  overflow: hidden; /* Added overflow to hide overflowing content */
}

nav a {
  color: #fff;
  text-decoration: none;
  padding: 1rem 0.5rem; /* Adjusted padding to prevent overflow */
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Added transition for smoother effect */
}

nav a.active {
  background-color: #4d4dff;
  padding: 1rem 1rem; /* Adjusted padding for the active state */
}

/* Bars Icon Styles */
.bars-icon {
  display: none;
  color: #fff;
}

@media screen and (max-width: 1024)  {
  .bars-icon {
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.8rem;
    cursor: pointer;
  }

  nav {
    padding: 0 1rem;
  }

  nav a {
    display: none;
  }

  .nav-menu {
    display: flex;
    align-items: center;
    margin-right: 0;
  }
}

#gallery {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}

.item {
  flex: 1 1 auto;
  height: 33vh;
}

.item img {
  object-fit: cover;
  height: 95%;
  width: 95%;
  max-width: 50vh;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  z-index: 999; /* ensures it's on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.modal img {
  object-fit: scale-down;
  max-height: 80vh;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}